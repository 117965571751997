import { dom } from "./baseDom";
import { notNullOrDie } from "../helpers";
import { Language } from "../locale";

fetch("/locale").then(async (response) => {
  const locale: Record<string, Record<string, string>> = await response.json();
  dom.jsRu.removeAttribute("disabled");
  dom.jsEn.removeAttribute("disabled");

  function changeLanguage(language: Language) {
    dom.html.setAttribute("lang", language);
    setCookie("lang", language, 1000);
    document.querySelectorAll("[data-t]").forEach((x) => {
      const key = notNullOrDie(x.getAttribute("data-t"));
      x.innerHTML = locale[key][language];
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  dom.jsRu.addEventListener("click", () => {
    changeLanguage("ru");
  });
  dom.jsEn.addEventListener("click", () => {
    changeLanguage("en");
  });
});

function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
