export function query<K extends keyof HTMLElementTagNameMap | "svg" = "div">(
  selectors: string,
): K extends keyof HTMLElementTagNameMap
  ? HTMLElementTagNameMap[K]
  : SVGSVGElement {
  return document.querySelector(selectors)!;
}

export function queryInside<
  K extends keyof HTMLElementTagNameMap | "svg" = "div",
>(
  el: HTMLElement,
  selectors: string,
): K extends keyof HTMLElementTagNameMap
  ? HTMLElementTagNameMap[K]
  : SVGSVGElement {
  return el.querySelector(selectors)!;
}

export function queryAll<K extends keyof HTMLElementTagNameMap = "div">(
  selectors: string,
): HTMLElementTagNameMap[K][] {
  return Array.from(document.querySelectorAll(selectors));
}

export function queryAllInside<K extends keyof HTMLElementTagNameMap>(
  el: HTMLElement,
  selectors: string,
): HTMLElementTagNameMap[K][] {
  return Array.from(el.querySelectorAll(selectors));
}

export function hide(el: HTMLElement) {
  el.classList.add("hidden");
}

export function show(el: HTMLElement) {
  el.classList.remove("hidden");
}
