export function mustNeverExist(x: never) {
    return new Error("Unexpected value " + x);
}

export type Equal<X, Y> = (<T>() => T extends X ? 1 : 2) extends <
    T
>() => T extends Y ? 1 : 2
    ? true
    : false;

type NotExactNull<T> = Equal<T, null> extends true ? never : T;

type Nullable<T> = null extends T ? T : never;

type NotExactUndefined<T> = Equal<T, undefined> extends true ? never : T;

type Undefinable<T> = undefined extends T ? T : never;

type NullableUndefinable<T> = null extends T
    ? T
    : undefined extends T
    ? T
    : never;

type NotExactNullUndefined<T> = Equal<T, undefined | null> extends true
    ? never
    : Equal<T, undefined> extends true
    ? never
    : Equal<T, null> extends true
    ? never
    : T;

export function isNotNull<T>(
    arg: NotExactNull<Nullable<T>>
): arg is Exclude<NotExactNull<Nullable<T>>, null> {
    return arg !== null;
}

export function isDefined<T>(
    arg: NotExactUndefined<Undefinable<T>>
): arg is Exclude<NotExactUndefined<Undefinable<T>>, undefined> {
    return arg !== undefined;
}

export function isDefinedNotNull<T>(
    arg: NotExactNullUndefined<NullableUndefinable<T>>
): arg is Exclude<
    Exclude<NotExactNullUndefined<NullableUndefinable<T>>, null>,
    undefined
> {
    return isNotNull(arg as any) && isDefined(arg as any);
}

export function assertDefined<T>(
    arg: NotExactUndefined<Undefinable<T>>,
    message?: string
): asserts arg is Exclude<NotExactUndefined<Undefinable<T>>, undefined> {
    if (arg === undefined) {
        throw new Error(message ?? "Argument was undefined");
    }
}

export function assertNotNull<T>(
    arg: NotExactNull<Nullable<T>>,
    message?: string
): asserts arg is Exclude<NotExactNull<Nullable<T>>, null> {
    if (arg === null) {
        throw new Error(message ?? "Argument was null");
    }
}

export function definedOrDie<T>(
    arg: NotExactUndefined<Undefinable<T>>,
    message?: string
): Exclude<T, undefined> {
    if (arg === undefined) {
        throw new Error(message ?? "Argument was undefined");
    }
    return arg as Exclude<T, undefined>;
}

export function notNullOrDie<T>(
    arg: NotExactNull<Nullable<T>>,
    message?: string
): Exclude<T, null> {
    if (arg === null) {
        throw new Error(message ?? "Argument was null");
    }
    return arg as Exclude<T, null>;
}
